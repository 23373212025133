import * as React from 'react';
import { useEffect } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { useTranslation } from "react-i18next";

import { useProfile } from "../Context/QueueContext";

import ControlPanel from './ControlPanel';
import SongInfo from './SongInfo';

export default function Overlay() {
    const { requestedSongs } = useProfile();
    const { t } = useTranslation();

    const playingSong = requestedSongs.filter(element => element.active === true).shift();
    const nextSong = requestedSongs.filter(element => element.active === false || element.active === undefined).shift();

    useEffect(() => {
    }, [requestedSongs]);

    return (
        <Box sx={{
            backgroundColor: '#000000',
        }}>
            <Grid container justifyContent="center" alignItems="center" display="flex">
                <Grid item xs={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                sx={{ color: '#fff', fontSize: { xs: 14, md: 32 } }}
                                gutterBottom
                                component="div"
                            >
                                {t('In Game:')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {playingSong ? (
                                <Box
                                    component="img"
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        maxHeight: "240px",
                                        maxWidth: "240px"
                                    }}
                                    alt={playingSong.name}
                                    src={playingSong.image}
                                />
                            ) : (
                                <Typography
                                    gutterBottom
                                    sx={{ color: '#fff', fontSize: { xs: 14, md: 32 } }}
                                    variant="h5"
                                    component="div"
                                >
                                    {nextSong ? t('NOT READY') : t('NO LIST')}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <SongInfo song={playingSong} />
                </Grid>
                <Grid item xs={3}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography
                                gutterBottom
                                sx={{ color: '#fff', fontSize: { xs: 14, md: 32 } }}
                                variant="h5"
                                component="div"
                            >
                                {t('Up Next:')}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {nextSong ? (
                                <Box
                                    component="img"
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        maxHeight: "240px",
                                        maxWidth: "240px"
                                    }}
                                    alt={nextSong.name}
                                    src={nextSong.image}
                                />
                            ) : (
                                <Typography
                                    gutterBottom
                                    sx={{ color: '#fff', fontSize: { xs: 14, md: 32 } }}
                                    variant="h5"
                                    component="div"
                                >
                                    {t('NO LIST')}
                                </Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <ControlPanel />
        </Box >
    )
}