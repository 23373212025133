import { createContext, useContext, useMemo, useState, useEffect } from "react"

import firebaseApp from '../firebase';
import { collection, query, orderBy, onSnapshot, getFirestore, setDoc, doc, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { useProfile as userProfile } from '../Context/UserContext';

import { useTranslation } from "react-i18next";

const firestore = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

const QueueContext = createContext();

export function QueueProvider(props) {
    const [requestedSongs, setRequestedSongs] = useState([]);
    const [cemetery, setCemetery] = useState([]);
    const [config, setConfig] = useState({ adminUids: [], openQueue: false });

    const { user, fcmToken, getAccessToken } = userProfile();

    const { t } = useTranslation();

    const token = getAccessToken();

    const isAdmin = config?.adminIds ? config.adminIds.includes(user?.uid) : false;

    function loadRequestedSongs() {
        const requestedSongsColRef = query(collection(firestore, 'queue'), orderBy("votes", "desc"), orderBy("timestamp", "asc"));
        onSnapshot(requestedSongsColRef, (snapshot) => {
            setRequestedSongs(snapshot.docs.sort(function (a, b) { return (b.data().active ? 1 : -1) - (a.data().active ? 1 : -1) }).map(doc => (
                {
                    code: doc.id,
                    active: doc.data().active,
                    name: doc.data().name,
                    mix: doc.data().mix,
                    image: doc.data().image,
                    votes: doc.data().votes,
                    users: doc.data().users
                }
            )))
        });
    }

    function loadCemetery() {
        const cemeteryColRef = query(collection(firestore, 'cemetery'));
        onSnapshot(cemeteryColRef, (snapshot) => {
            setCemetery(snapshot.docs.map(doc => (
                {
                    code: doc.id,
                    name: doc.data().name,
                    image: doc.data().image
                }
            )))
        });
    }

    function loadConfig() {
        const configDocRef = doc(firestore, 'config', 'main');
        onSnapshot(configDocRef, (document) => {
            setConfig(
                {
                    openQueue: document.data().openQueue,
                    adminIds: document.data().adminUids
                }
            )
        });
    }

    async function queueRequestSong(song) {
        if (!user.uid) {
            return ({
                severity: 'error',
                message: t('Please authenticate, click on right avatar')
            })
        }
        //check validity
        if (!song) {
            return ({
                severity: 'error',
                message: t('Please select a song')
            })
        } else if (!song.id) {
            return ({
                severity: 'error',
                message: t('Please select a valid song')
            })
        }
        // check if song is in cementery
        if (cemetery.filter(element => element.code === song.id).length) {
            return ({
                severity: 'warning',
                message: t('Song already played')
            })
        }

        const requestedSong = requestedSongs.filter(element => element.code === song.id);
        if (requestedSong.length) {
            return upvoteSong(song, requestedSong.pop());
        } else {
            //queue new song
            let newQueue = {
                active: false,
                code: song.id,
                image: process.env.REACT_APP_IMAGE_SERVER + '/' + song.id,
                mix: song.version,
                name: song.name,
                votes: user.isAnonymous ? 1 : (isAdmin ? 999 : 2),
                timestamp: Date.now(),
                users: {}
            }
            if (user) {
                newQueue.users[user.uid] = {
                    avatar: user.photoURL + (token ? token : ''),
                    name: user.displayName,
                    auth: !user.isAnonymous,
                    fcmToken: fcmToken ? fcmToken : null
                }
            }
            await setDoc(doc(firestore, "queue", song.id), newQueue);
            return ({
                severity: 'success',
                requestType: 'added',
                requestName: song.name,
                message: t('Song added')
            })
        }
    }

    async function upvoteSong(song, requestedSong = false) {
        if (!user) {
            return ({
                severity: 'error',
                message: t('Please authenticate, click on right avatar')
            })
        }
        if (!requestedSong) {
            requestedSong = requestedSongs.filter(element => element.code === song.id).pop();
        }
        if (!requestedSong) {
            return ({
                severity: 'error',
                message: t('Upvoting a non requested song')
            })
        }
        if (requestedSong.users[user.uid]) {
            return ({
                severity: 'error',
                message: t('You already requested this song')
            })
        }
        requestedSong.votes = requestedSong.votes + (user.isAnonymous ? 1 : (isAdmin ? 999 : 2))
        if (user) {
            requestedSong.users[user.uid] = {
                avatar: user.photoURL + (token ? token : ''),
                name: user.displayName,
                auth: !user.isAnonymous,
                fcmToken: fcmToken ? fcmToken : null
            }
        }
        const requestedSongRef = doc(firestore, "queue", song.id);
        await updateDoc(requestedSongRef, requestedSong);
        return ({
            severity: 'success',
            requestType: 'upvote',
            requestName: requestedSong.name,
            message: t('Adding your vote to this song')
        })
    }

    function nextSong(liveLink = null) {
        const playingSong = requestedSongs.filter(element => element.active === true).shift();
        const nextSong = requestedSongs.filter(element => element.active === false || element.active === undefined).shift();

        if (playingSong) {
            deleteDoc(doc(firestore, "queue", playingSong.code));
            setDoc(doc(firestore, "cemetery", playingSong.code), {
                code: playingSong.code,
                image: playingSong.image,
                name: playingSong.name
            });
            delete (requestedSongs[playingSong.code]);
        }

        if (nextSong) {
            notifyUsers(liveLink, nextSong);
            const nextSongRef = doc(firestore, "queue", nextSong.code);
            updateDoc(nextSongRef, { active: true });
            nextSong.active = true;
        }
    }

    async function sendMessageToSocial(result, source = 'request') {
        if (!user || result.severity != 'success') {
            return;
        }

        const providerData = user.providerData[0]

        const queryParameters = new URLSearchParams(window.location.search);
        const socialStreamSessionKey = queryParameters.get('socialId') ? queryParameters.get('socialId') : process.env.REACT_APP_SOCIALSTREAM_KEY;
        const displayName = user.displayName ? user.displayName : t('Anonymous')
        let chatMessage = t('{{userName}} requested a song', { userName: displayName })
        if (source == 'random') {
            chatMessage = t('{{userName}} requested a random song', { userName: displayName })
        } else if (source == 'upvote') {
            chatMessage = t('{{userName}} upvoted {{song}}', { userName: displayName, song: result.requestName })
        } else {
            chatMessage = t('{{userName}} requested {{song}}', { userName: displayName, song: result.requestName })
        }
        let payload
        if (providerData) {
            payload = {
                "content": {
                    "chatname": displayName,
                    "chatmessage": chatMessage,
                    "type": translateProviderDataToSocialStream(providerData.providerId),
                    "id": providerData.uid
                },
                "apiid": socialStreamSessionKey
            }
        } else {
            payload = {
                "action": "sendChat",
                "value": chatMessage,
                "apiid": socialStreamSessionKey
            }
        }
        fetch(
            process.env.REACT_APP_SOCIALSTREAM_URL + socialStreamSessionKey,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": window.location.origin
                },
                body: JSON.stringify(payload),
            }
        )
    }

    function translateProviderDataToSocialStream(providerId) {
        let socialStreamId;
        switch (providerId) {
            case 'google.com':
                socialStreamId = 'meet';
                break;
            case 'facebook.com':
                socialStreamId = 'facebook';
                break;
            case 'twitter.com':
                socialStreamId = 'twitter';
                break;
            default:
                socialStreamId = null;
                break;
        }
        return socialStreamId;
    }

    async function notifyUsers(liveLink, nextSong, message = null) {
        if (!nextSong.users) {
            return;
        }
        Object.keys(nextSong.users).forEach(async function (userId) {
            if (nextSong.users[userId].fcmToken) {
                let payload = {
                    "url": 'https://fcm.googleapis.com/v1/projects/' + process.env.REACT_APP_FIREBASE_PROJECT_ID + '/messages:send',
                    "body": {
                        "message": {
                            "token": nextSong.users[userId].fcmToken,
                            "notification": {
                                "body": message ? message : t("Your requested song is soon to be played"),
                                "title": t("Song Ready"),
                                "image": nextSong.image,
                            },
                            "data": {
                                "song": nextSong.name,
                                "banner": nextSong.image,
                                "code": nextSong.code
                            },
                            "webpush": {
                                "fcm_options": {
                                    "link": window.location.origin,
                                },
                                "data": {
                                    "song": nextSong.name,
                                    "banner": nextSong.image,
                                    "code": nextSong.code
                                },
                                "notification": {
                                    "body": message ? message : t("Your requested song is soon to be played"),
                                    "title": t("Song Ready"),
                                    "badge": 'https://cgarcia.co/favicon-32x32.png',
                                    "tag": "notification-" + nextSong.code,
                                    "image": nextSong.image,
                                    "icon": nextSong.image,
                                    "actions": [
                                        { action: 'view_queue', title: t('View Queue') }
                                    ]
                                },
                            },
                        }
                    }
                }
                if (liveLink) {
                    payload.body.message.data.liveLink = liveLink;
                    payload.body.message.webpush.fcm_options.link = liveLink;
                    payload.body.message.webpush.notification.actions.push({ action: liveLink, title: t('View Live') });
                }
                await fetch(
                    process.env.REACT_APP_PROXY_SERVER,
                    {
                        method: 'POST',
                        mode: 'cors',
                        headers: {
                            "Content-Type": "application/json"
                        },
                        body: JSON.stringify(payload),
                    }
                )
            }
        });
    }

    async function emptyQueue() {
        ['queue', 'cemetery'].forEach(async table => {
            const querySnapshot = await getDocs(collection(firestore, table));
            querySnapshot.forEach((document) => {
                deleteDoc(doc(firestore, table, document.id));
            });
        })
    }

    function flipQueue() {
        if (config && config.adminIds.includes(user?.uid)) {
            const configDocRef = doc(firestore, 'config', 'main');
            updateDoc(configDocRef, {
                openQueue: !config.openQueue
            });
        }
    }

    async function getTicTacToe(callback) {
        onSnapshot(doc(firestore, 'config', 'tictactoe'), (docSnap) => {
            if (docSnap.exists()) {
                callback(docSnap.data());
            } else {
                callback({
                    player1: t('Player 1'),
                    avatar1: '/images/cross.png',
                    player2: t('Player 2'),
                    avatar2: '/images/circle.png',
                    grid: [],
                });
            }
        });
    }

    async function setTicTacToe(data) {
        //avatars
        let avatar1 = data.avatar1 ? data.avatar1 : '/images/cross.png';
        if (data.avatar1.size) {
            avatar1 = await uploadAvatar(data.avatar1, 'tictactoe-player1')
        }
        let avatar2 = data.avatar2 ? data.avatar2 : '/images/circle.png';
        if (data.avatar2.size) {
            avatar2 = await uploadAvatar(data.avatar2, 'tictactoe-player2')
        }

        await setDoc(doc(firestore, "config", "tictactoe"), { ...data, avatar1, avatar2 });
    }

    async function uploadAvatar(file, id) {
        const remoteUrl = ref(storage, id);
        await uploadBytes(remoteUrl, file);
        return getDownloadURL(remoteUrl);
    }

    function newGrid(playGrid = [], missingOnly = false, liveLink = null) {
        let i = 0;
        let requestI = 0;
        let grid = playGrid;
        while (i < 9) {
            if (!missingOnly || (missingOnly && !grid[i]?.song)) {
                //needs to be filled
                const songToFill = requestedSongs[requestI];
                requestI++;
                if (songToFill) {
                    deleteDoc(doc(firestore, "queue", songToFill.code));
                    setDoc(doc(firestore, "cemetery", songToFill.code), {
                        code: songToFill.code,
                        image: songToFill.image,
                        name: songToFill.name
                    });
                    notifyUsers(liveLink, songToFill, t('Your requested song is in tictactoe mode'));
                }
                grid[i] = {
                    inBattle: false,
                    winner: false,
                    highlight: false,
                    idx: i,
                    song: songToFill ? songToFill : false
                }
            }
            i++;
        }
        return grid;
    }

    useEffect(() => {
        loadConfig();
        loadRequestedSongs();
        loadCemetery();
    }, [user, fcmToken]);

    const value = useMemo(() => {
        return ({
            requestedSongs,
            cemetery,
            config,
            loadRequestedSongs,
            loadCemetery,
            queueRequestSong,
            sendMessageToSocial,
            upvoteSong,
            nextSong,
            emptyQueue,
            flipQueue,
            getTicTacToe,
            setTicTacToe,
            newGrid
        })
    }, [requestedSongs, cemetery, config])

    return <QueueContext.Provider value={value} {...props} />
}

export function useProfile() {
    const context = useContext(QueueContext);
    if (!context) {
        throw new Error('useProfile should be inside provider QueueContext')
    }

    return context;
}