import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Box from '@mui/material/Box';

import { useTranslation } from 'react-i18next';

const lngs = {
    en: {
        nativeName: "English",
        flagLink: "https://flagcdn.com/us.svg",
        flagLinkSet: "https://flagcdn.com/us.svg 2x"
    },
    es: {
        nativeName: "Español",
        flagLink: "https://flagcdn.com/es.svg",
        flagLinkSet: "https://flagcdn.com/es.svg 2x"
    }
}

const LanguageSelector = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
    }

    return (
        <FormControl variant="standard" sx={{ width: '100%', maxHeight: '50px' }}>
            <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={i18n.resolvedLanguage}
                label={t('Language')}
                onChange={changeLanguage}
                IconComponent={() => null}
                variant="standard"
                renderValue={value => <Box
                    component="img"
                    sx={{
                        height: "100%",
                        width: "100%",
                        maxHeight: { xs: 25, md: 50 },
                        maxWidth: { xs: 25, md: 50 },
                    }}
                    src={lngs[value].flagLink}
                    srcSet={lngs[value].flagLinkSet}
                    alt={lngs[value].nativeName}
                    loading="lazy"
                />}
            >
                {Object.keys(lngs).map((lng) => (
                    <MenuItem sx={{ maxWidth: { xs: '50px', md: '100px' } }} key={lng} value={lng} style={{ color: "white" }}>
                        <img
                            src={lngs[lng].flagLink}
                            srcSet={lngs[lng].flagLinkSet}
                            width={"100%"}
                            alt={lngs[lng].nativeName}
                            loading="lazy"
                        />
                    </MenuItem>
                ))}
            </Select>
        </FormControl >
    )
}

export default LanguageSelector;