import { useEffect, useState } from "react";

import { useProfile as userProfile } from "../Context/UserContext";
import { useProfile as queueProfile } from "../Context/QueueContext";

import { Box, Grid, Typography } from "@mui/material";
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { useTranslation } from "react-i18next";

import SearchBar from "./SearchBar";

export default function ControlPanel() {
    const [open, setOpen] = useState(false);
    const [liveLink, setLiveLink] = useState(false);

    const { requestedSongs, cemetery, config, nextSong, flipQueue, emptyQueue } = queueProfile();
    const { user } = userProfile();

    const isAdmin = config?.adminIds ? config.adminIds.includes(user?.uid) : false;
    const queueOpen = config?.openQueue;

    const { t } = useTranslation();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    function handleEmptyQueue() {
        handleClose();
        emptyQueue();
    }

    useEffect(() => { }, [user, requestedSongs, config]);

    return (
        isAdmin ?
            <Box sx={{ backgroundColor: '#fff', m: 0.5 }}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom component="div">{t('Admin')}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack spacing={2} direction="row">
                            <Button disabled={requestedSongs.length === 0} onClick={() => { nextSong(liveLink) }} variant="contained">{t('Next Song')}</Button>
                            <Button disabled={requestedSongs.length === 0 && cemetery.length === 0} onClick={handleClickOpen} variant="contained">{t('Empty Queue')}</Button>
                            <Button onClick={flipQueue} variant="contained">{t(queueOpen ? 'Close Queue' : 'Open Queue')}</Button>
                            <TextField id="livelLink" onChange={(event) => { setLiveLink(event.target.value); }} label={t('Live Link')} variant="outlined" sx={{ width: '40%' }} />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <SearchBar />
                    </Grid>
                </Grid>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {t("Are you sure?")}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {t('This will delete all songs in queue and cemetery')}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>{t('Close')}</Button>
                        <Button onClick={handleEmptyQueue} autoFocus>
                            {t('Confirm')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>
            :
            ''
    )
}