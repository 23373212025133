import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import PersonIcon from '@mui/icons-material/Person';

import { useTranslation } from "react-i18next";

import { useProfile } from '../Context/UserContext';
import { Grid } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '95%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function ImageAvatars() {
    const { user, loginWith, getAccessToken } = useProfile();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);

    const { t } = useTranslation();

    function handleClose() {
        setOpen(false);
        if (!user.uid) {
            loginWith('guest');
        }
    }

    useEffect(() => {
        setOpen(user.uid === undefined)
    }, [user]);

    const token = getAccessToken();

    const providerData = user?.providerData;

    return (
        <>
            <Box alignItems="center"
                display="flex"
                flexDirection="column"
                gap={4}
                justifyContent="center"
                width={'100%'}>
                {user.uid && !user.isAnonymous ? (
                    <Avatar sx={{ width: "50%", height: "50%", maxHeight: "50px", maxWidth: "50px" }} onClick={handleOpen} alt={user.displayName} src={user.photoURL + ((token && (user.providerData[0]['providerId'] != 'twitter.com')) ? token : '')} />
                ) : (
                    <Avatar sx={{ width: { xs: '24px', sm: '56px' }, height: { xs: '24px', sm: '56px' } }} onClick={handleOpen} alt={t("Guest")}> <PermIdentityIcon /></Avatar>
                )}
            </Box>
            <Modal
                open={open}
                onClose={() => { handleClose() }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                {t(user.uid === undefined ? "Log In" : "Change User")}
                            </Typography>
                        </Grid>
                        <Grid item xs={1} sx={{ color: 'gray', width: '30px', display: user.uid === undefined ? 'none' : 'block' }} textAlign='center'>
                            <CloseIcon onClick={handleClose} />
                        </Grid>
                        <Grid item xs={12}>
                            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                                {t("Now you can login to get the double of votes, which allows to be your songs on top of the list")}
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} sm={3}>
                                    <IconButton
                                        disabled={(providerData && providerData[0]) ? providerData[0]['providerId'] === 'facebook.com' : false}
                                        onClick={() => { loginWith('facebook') }}
                                        sx={{
                                            border: "2px solid #ccc",
                                            borderRadius: "5px",
                                            padding: "0.5675rem",
                                            flex: 1,
                                            width: "100%"
                                        }}
                                    >
                                        <FacebookIcon color="#DF3E30" width={22} height={22} /> Facebook
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <IconButton
                                        disabled={(providerData && providerData[0]) ? providerData[0]['providerId'] === 'google.com' : false}
                                        onClick={() => { loginWith('google') }}
                                        sx={{
                                            border: "2px solid #ccc",
                                            borderRadius: "5px",
                                            padding: "0.5675rem",
                                            flex: 1,
                                            width: "100%"
                                        }}
                                    >
                                        <GoogleIcon color="#1877F2" width={22} height={22} /> Google
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <IconButton
                                        disabled={(providerData && providerData[0]) ? providerData[0]['providerId'] === 'twitter.com' : false}
                                        onClick={() => { loginWith('twitter') }}
                                        sx={{
                                            border: "2px solid #ccc",
                                            borderRadius: "5px",
                                            padding: "0.5675rem",
                                            flex: 1,
                                            width: "100%"
                                        }}
                                    >
                                        <TwitterIcon color="#1C9CEA" width={22} height={22} /> Twitter
                                    </IconButton>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <IconButton
                                        disabled={user.isAnonymous}
                                        onClick={() => { loginWith('guest') }}
                                        sx={{
                                            border: "2px solid #ccc",
                                            borderRadius: "5px",
                                            padding: "0.5675rem",
                                            flex: 1,
                                            width: "100%"
                                        }}
                                    >
                                        <PersonIcon color="#1C9CEA" width={22} height={22} /> {t(user.uid === undefined ? "Dont Login" : "Log Out")}
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>



    );
}