import * as React from 'react';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';

import Marquee from "react-fast-marquee";

import { useTranslation } from "react-i18next";

import { useProfile as queueProfile } from "../Context/QueueContext";


export default function SongInfo(props) {
    const playingSong = props.song;

    const { config } = queueProfile();

    const [overflowed, setOverflowed] = useState(false);
    const ref = useRef(null);

    const { t } = useTranslation();

    const queueOpen = config?.openQueue;

    useEffect(() => {
        if (!ref.current.innerHTML) {
            return;
        }
        const newOverflowed = ref.current ? ref.current.offsetWidth < ref.current.scrollWidth : false;
        setOverflowed(newOverflowed);
    }, [props, ref.current]);

    return (
        <Grid container>
            <Grid item xs={12} sx={{ display: overflowed ? 'none' : 'block' }}>
                <Typography ref={ref}
                    sx={{ color: '#fff', fontSize: { xs: 14, md: 42 }, whiteSpace: 'nowrap', overflow: 'hidden' }}
                    variant="h5"
                    component="div"
                >
                    {playingSong?.name}
                </Typography>
            </Grid>
            <Grid item xs={12} sx={{ display: overflowed ? 'block' : 'none', color: '#fff', fontSize: { xs: 14, md: 42 } }}>
                <Marquee speed={80} gradient={false}>{playingSong?.name}</Marquee>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{ color: '#fff', fontSize: { xs: 14, md: 42 } }}
                    variant="h5"
                    component="div"
                >
                    {playingSong?.mix ? (
                        <>
                            <b>Pack</b>: {playingSong?.mix}
                        </>
                    ) : ''}
                </Typography>
            </Grid>
            <Grid item xs={12}><Typography gutterBottom></Typography></Grid>
            {playingSong?.users ? (
                <Box alignItems="center"
                    display="flex"
                    flexDirection="column"
                    gap={4}
                    justifyContent="center"
                    width={'100%'}>

                    <AvatarGroup max={8} sx={{
                        '& .MuiAvatar-root': {
                            width: {
                                xs: 25, // theme.breakpoints.up('xs')
                            }, height: {
                                xs: 25, // theme.breakpoints.up('xs')
                            }, fontSize: 15
                        },
                        display: { xs: 'flex', md: 'none' }
                    }}>
                        {Object.keys(playingSong.users).filter(voteUserId => playingSong.users[voteUserId].auth === true).map(function (voteUserId) {
                            return (<Avatar alt={playingSong.users[voteUserId].name} key={"vote-xs-" + voteUserId} src={playingSong.users[voteUserId].avatar} sx={{ width: 24, height: 24 }} />)
                        })}
                    </AvatarGroup>
                    <AvatarGroup max={11} sx={{
                        '& .MuiAvatar-root': {
                            width: {
                                xs: 42, // theme.breakpoints.up('xs')
                            }, height: {
                                xs: 42, // theme.breakpoints.up('xs')
                            }, fontSize: 15
                        },
                        display: { xs: 'none', md: 'flex', lg: 'none' }
                    }}>
                        {Object.keys(playingSong.users).filter(voteUserId => playingSong.users[voteUserId].auth === true).map(function (voteUserId) {
                            return (<Avatar alt={playingSong.users[voteUserId].name} key={"vote-md-" + voteUserId} src={playingSong.users[voteUserId].avatar} sx={{ width: 24, height: 24 }} />)
                        })}
                    </AvatarGroup>
                    <AvatarGroup max={13} sx={{
                        '& .MuiAvatar-root': {
                            width: {
                                xs: 52, // theme.breakpoints.up('xs')
                            }, height: {
                                xs: 52, // theme.breakpoints.up('xs')
                            }, fontSize: 15
                        },
                        display: { xs: 'none', md: 'none', lg: 'flex' },
                    }}>
                        {Object.keys(playingSong.users).filter(voteUserId => playingSong.users[voteUserId].auth === true).map(function (voteUserId) {
                            return (<Avatar alt={playingSong.users[voteUserId].name} key={"vote-lg-" + voteUserId} src={playingSong.users[voteUserId].avatar} sx={{ width: 24, height: 24 }} />)
                        })}
                    </AvatarGroup>

                </Box>
            ) : ('')}
            <Grid item xs={12}><Typography gutterBottom></Typography></Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{ color: '#fff', fontSize: { xs: 14, md: 32 } }}
                    variant="h5"
                    component="div"
                >
                    {t('Request your song:')}
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography
                    sx={{ color: '#fff', fontSize: { xs: 14, md: 32 } }}
                    variant="h5"
                    component="div"
                >
                    {queueOpen ? window.location.origin : '--' + t('Queue Closed') + '--'}
                </Typography>
            </Grid>
        </Grid>
    )
}