import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Grid } from "@mui/material";
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useProfile as userProfile } from "../Context/UserContext";
import { useProfile as queueProfile } from "../Context/QueueContext";

import SongInfo from "./SongInfo";

export default function TicTacToe() {
    const [data, setData] = useState({ grid: [] })
    const { t } = useTranslation();


    const { config, getTicTacToe, setTicTacToe, newGrid } = queueProfile();
    const { user } = userProfile();

    const isAdmin = config?.adminIds ? config.adminIds.includes(user?.uid) : false;
    const [player1, setPlayer1] = useState();
    const [player2, setPlayer2] = useState();
    const [avatar1, setAvatar1] = useState({});
    const [avatar2, setAvatar2] = useState({});
    const [player1Score, setPlayer1Score] = useState();
    const [player2Score, setPlayer2Score] = useState();
    const [playingSong, setPlayingSong] = useState();
    const [gameFinished, setGameFinished] = useState(false);

    const gridStyles = {
        maxHeight: { xs: '94px', sm: '149px', md: '300px', lg: '399px', xl: '512px' },
        borderColor: '#fff',
        padding: '2px'
    }

    const Input = styled('input')({
        display: 'none',
    });

    function refreshTicTacToe(data) {
        setData(data);
        setPlayer1(data.player1);
        setAvatar1(data.avatar1);
        setPlayer2(data.player2);
        setAvatar2(data.avatar2);
        if ((data.grid.filter(element => element.inBattle).length > 0)) {
            setPlayingSong(data.grid.filter(element => element.inBattle).shift().song)
        } else {
            setPlayingSong(false);
        }
        checkWinner(data);
    }

    function checkWinner(data) {
        if (data.grid.length !== 9) {
            return;
        }
        const possibilities = [
            [0, 1, 2],
            [3, 4, 5],
            [6, 7, 8],
            [0, 3, 6],
            [1, 4, 7],
            [2, 5, 8],
            [0, 4, 8],
            [2, 4, 6]
        ]
        const result = possibilities.filter(element =>
            data?.grid[element[0]].winner === data?.grid[element[1]].winner && data?.grid[element[1]].winner === data?.grid[element[2]].winner && data?.grid[element[1]].winner !== false
        )
        if (result.length) {
            const winner = result.shift();
            let tempData = data;
            tempData.grid[winner[0]].highlight = true;
            tempData.grid[winner[1]].highlight = true;
            tempData.grid[winner[2]].highlight = true;
            setTicTacToe(tempData);
            setGameFinished(data?.grid[winner[0]].winner);
        }
    }

    function loadTicTacToe() {
        getTicTacToe(refreshTicTacToe);
    }

    const fillSongs = (missingOnly = false) => {
        let newData = data;
        if (!missingOnly) {
            newData = {
                player1: t('Player 1'),
                avatar1: '/images/cross.png',
                player2: t('Player 2'),
                avatar2: '/images/circle.png',
                grid: [],
            }
            setGameFinished(false);
        }
        const grid = newGrid(newData.grid, missingOnly);
        const newTicTacToe = { ...newData, grid }
        setTicTacToe(newTicTacToe);
        setData(newTicTacToe)
    }

    function saveSettings() {
        setTicTacToe({
            player1: player1,
            avatar1: avatar1,
            player2: player2,
            avatar2: avatar2,
            grid: data.grid,
        })
    }


    function Area(props) {
        const status = props.status

        function handleArea(status) {
            if (!isAdmin || gameFinished || !status.song) {
                return;
            }
            const activeBattleIdx = data.grid.filter(element => element.inBattle);
            if (activeBattleIdx.length === 0) {
                let tempData = data;
                tempData.grid[status.idx].inBattle = true;
                setTicTacToe(tempData);
            } else {
                let tempData = data;
                tempData.grid[activeBattleIdx[0].idx].inBattle = false;
                tempData.grid[status.idx].inBattle = true;
                setTicTacToe(tempData);
            }
        }

        return (
            <Box sx={{
                position: "relative", left: 0, top: 0, height: "100%",
                width: "100%",
            }}>
                {status?.inBattle ?
                    <Box component="img"
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: "12%",
                            height: "100%",
                            maxHeight: gridStyles.maxHeight,
                            zIndex: 11
                        }}
                        src="/images/dispute.gif"
                    />
                    :
                    ''}
                {status?.winner ?
                    <Box component="img"
                        sx={{
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            maxHeight: gridStyles.maxHeight,
                            zIndex: 11
                        }}
                        src={status.winner === 'player1' ? data.avatar1 : data.avatar2}
                    />
                    :
                    ''}
                {status?.highlight ?
                    <Box component="div"
                        sx={{
                            position: "absolute",
                            top: 0,
                            height: "100%",
                            width: "100%",
                            maxHeight: gridStyles.maxHeight,
                            zIndex: 11,
                            background: 'green',
                            opacity: '.5'
                        }}
                    />
                    :
                    ''}
                <Box
                    onClick={(e) => { handleArea(status) }}
                    component="img"
                    sx={{
                        height: "100%",
                        width: "100%",
                        position: "relative",
                        left: 0,
                        top: 0
                    }}
                    alt={status?.song?.name}
                    src={status?.song?.image}
                />
            </Box>
        )
    }

    const handleChange = (event) => {
        const name = event.target.value;
        if (event.target.id === 'player1-name') {
            setPlayer1(name);
        } else {
            setPlayer2(name);
        }
    };

    const handleAvatar = (event) => {
        const files = Array.from(event.target.files);
        const [file] = files;
        if (event.target.id === 'avatar-player-1') {
            setAvatar1(file);
        } else {
            setAvatar2(file);
        }
    };

    const handleScore = (event) => {
        const score = event.target.value;
        if (event.target.id === 'player1-score') {
            setPlayer1Score(score);
        } else {
            setPlayer2Score(score);
        }
    };

    const saveScore = () => {
        const activeBattleIdx = data.grid.filter(element => element.inBattle);
        if (activeBattleIdx.length === 1) {
            const activeGrid = activeBattleIdx.shift();
            let tempData = data;
            tempData.grid[activeGrid.idx].inBattle = false;
            tempData.grid[activeGrid.idx].winner = parseInt(player1Score) > parseInt(player2Score) ? 'player1' : 'player2';
            setTicTacToe(tempData);
            setPlayer1Score();
            setPlayer2Score();
        }
    }

    useEffect(() => {
        loadTicTacToe();
    }, []);



    return (
        <Grid container>
            <Grid item xs={12}>
                <Grid container sx={{ backgroundColor: '#000', color: '#fff' }}>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[0]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[1]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[2]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[3]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[4]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[5]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[6]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[7]} /></Grid>
                    <Grid item xs={4} sx={{ ...gridStyles }}><Area status={data?.grid[8]} /></Grid>
                </Grid>
            </Grid>
            {gameFinished ?
                <Grid
                    container
                    sx={{ backgroundColor: 'green', color: 'red', }}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            sx={{ color: 'red', fontSize: { xs: 14, md: 32 } }}
                            variant="h5"
                            component="div"
                        >{t('Winner is {{winner}}', { winner: gameFinished === 'player1' ? data?.player1 : data?.player2 })}
                        </Typography>
                        <Box
                            component='img'
                            alt={gameFinished === 'player1' ? data?.player1 : data?.player2}
                            src={gameFinished === 'player1' ? data?.avatar1 : data?.avatar2}
                            sx={{ ...gridStyles }}
                        />
                    </Grid>
                </Grid>
                : ''
            }
            {
                !gameFinished ?
                    <Grid item xs={12}>
                        <Grid
                            container
                            sx={{ backgroundColor: '#000', color: '#fff' }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={2}>
                                {data?.player1}<br />
                                <Box
                                    component='img'
                                    alt={data?.player1}
                                    src={data?.avatar1}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                            <Grid item xs={8}>
                                {playingSong ? <SongInfo song={playingSong} /> : t('Select Next Song')}

                            </Grid>
                            <Grid item xs={2}>
                                {data?.player2}<br />
                                <Box
                                    component='img'
                                    alt={data?.player2}
                                    src={data?.avatar2}
                                    sx={{ width: '100%' }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    : ''
            }
            {
                (isAdmin && data.grid.filter(element => element.inBattle).length > 0 && !gameFinished) ?
                    <Grid item xs={12}>
                        <Grid
                            container
                            sx={{ backgroundColor: '#666' }}
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={4}>
                                <TextField id="player1-score" label={t("Player 1 Score")} variant="standard" value={player1Score || ''} onChange={handleScore} />
                            </Grid>
                            <Grid item xs={4}>
                                <TextField id="player2-score" label={t("Player 2 Score")} variant="standard" value={player2Score || ''} onChange={handleScore} />
                            </Grid>
                            <Grid item xs={4}>
                                <Button onClick={saveScore} variant='contained'>{t('Save Score')}</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    : ''
            }
            {
                isAdmin ?
                    <Grid item xs={12}>
                        <Grid container sx={{ backgroundColor: '#999' }}>
                            <Grid item xs={12}>
                                <Button onClick={(e) => fillSongs(false)}>{t('(Re)Start Game')}</Button>
                                <Button onClick={(e) => fillSongs(true)}>{t('Fill Missing')}</Button>
                                <Button onClick={saveSettings}>{t('Save Settings')}</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <TextField id="player1-name" label={t("Player 1 Name")} variant="standard" value={player1 || ''} onChange={handleChange} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box
                                                    component='img'
                                                    alt={t("Avatar Player 1 Picture")}
                                                    src={avatar1.name ? URL.createObjectURL(avatar1) : (data?.avatar1)}
                                                    sx={{ width: '60%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <label htmlFor="avatar-player-1">
                                                    <Input accept="image/*" id="avatar-player-1" multiple type="file" onChange={handleAvatar} />
                                                    <Button variant="contained" component="span">{t('Avatar Player 1')}</Button>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <TextField id="player2-name" label={t("Player 2 Name")} variant="standard" value={player2 || ''} onChange={handleChange} />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Box
                                                    component='img'
                                                    alt={t("Avatar Player 2 Picture")}
                                                    src={avatar2.name ? URL.createObjectURL(avatar2) : (data?.avatar2)}
                                                    sx={{ width: '60%' }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>

                                                <label htmlFor="avatar-player-2">
                                                    <Input accept="image/*" id="avatar-player-2" multiple type="file" onChange={handleAvatar} />
                                                    <Button variant="contained" component="span">{t('Avatar Player 2')}</Button>
                                                </label>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    : ''
            }
        </Grid >
    )
}