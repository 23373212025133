import * as React from 'react';
import { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';

import { useTranslation } from "react-i18next";

import { useProfile } from '../Context/UserContext';



export default function Notifier() {
    const { t } = useTranslation();

    const [open, setOpen] = useState(true);
    const [notifOpen, setNotifOpen] = useState(false);
    const [currentMessage, setCurrentMessage] = useState(t('I can notify you when your requested song is soon to be played'));
    const [snackDuration, setSnackDuration] = useState(6000)
    const [notification, setNotification] = useState({ title: '', body: '' });
    const { user, requestForToken, onMessageListener } = useProfile();

    const notify = () => <ToastDisplay />;
    function ToastDisplay() {
        return (
            notification.title ?
                <Snackbar
                    open={notifOpen}
                    autoHideDuration={4000}
                    onClose={handleNotifClose}
                    action={showNotification}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                >
                </Snackbar>
                : ''
        );
    };

    const showNotification = (
        <React.Fragment>
            {notification?.data?.song
                ? <Grid container>
                    <Grid item xs={4}>
                        <Box
                            component="img"
                            sx={{
                                height: { xs: 60, md: 100 },
                                width: { xs: 80, md: 120 },
                            }}
                            alt={notification?.data?.song}
                            src={notification?.data?.banner}
                        />
                    </Grid>
                    <Grid item xs={7}>
                        {t(notification?.body)}
                        <br />
                        {t('song_translate', { song_name: notification?.data?.song })}
                        <br />
                        {notification?.data?.liveLink ?
                            <Link href={notification?.data?.liveLink} target='_blank' >{t('View Here')}</Link>
                            :
                            <></>
                        }
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={handleNotifClose}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </Grid>
                </Grid>
                : <>
                    {t(notification?.body)}
                </>
            }
        </React.Fragment>
    )

    const handleClose = () => {
        setOpen(false);
    };

    const handleNotifClose = () => {
        setNotifOpen(false);
    }

    const enableNotification = () => {
        setOpen(false);
        requestForToken(setOpen, setCurrentMessage, setSnackDuration);
    }

    onMessageListener()
        .then((payload) => {
            setNotification({
                title: payload?.notification?.title,
                body: payload?.notification?.body,
                data: payload?.data
            });
        })
        .catch((err) => console.log('failed: ', err));

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={enableNotification}>
                {t('Please!')}
            </Button>
            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleClose}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        if (notification?.title) {
            setNotifOpen(true);
            notify();
        }
        const fcm_token = localStorage.getItem('fcm_token');
        if (fcm_token) {
            setOpen(false);
            requestForToken(setOpen, setCurrentMessage, setSnackDuration);
        }
    }, [user, notification]);

    return (
        user.uid ? (<div>
            <ToastDisplay />
            <Snackbar
                open={open}
                autoHideDuration={snackDuration}
                onClose={handleClose}
                message={t(currentMessage)}
                action={action}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
        </div>) : ('')
    );
}
