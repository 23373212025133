import React, { useEffect } from 'react';
import { Snackbar, Button } from '@mui/material';

import { register } from '../serviceWorkerRegistration';

import { useTranslation } from "react-i18next";

const ServiceWorkerWrapper = () => {
    const [showReload, setShowReload] = React.useState(false);
    const [waitingWorker, setWaitingWorker] = React.useState(ServiceWorker | null);
    const [offlineMode, setOfflineMode] = React.useState(false);

    const { t } = useTranslation();


    const onSWUpdate = (registration) => {
        setShowReload(true);
        setWaitingWorker(registration.waiting);
    };

    useEffect(() => {
        register({ onUpdate: onSWUpdate });
    }, []);

    const reloadPage = () => {
        waitingWorker?.postMessage({ type: 'SKIP_WAITING' });
        setShowReload(false);
        window.location.reload(true);
    };

    window.addEventListener('offline', () => {
        setOfflineMode(true);
    });

    window.addEventListener('online', () => {
        setOfflineMode(false);
    });

    return (
        <>
            <Snackbar
                open={offlineMode}
                message={t('No Internet Connection Detected')}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            />
            <Snackbar
                open={showReload}
                message={t("A new version is available!")}
                onClick={reloadPage}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                action={
                    <Button
                        color="inherit"
                        size="small"
                        onClick={reloadPage}
                    >
                        {t('Reload')}
                    </Button>
                }
            />
        </>
    );
}

export default ServiceWorkerWrapper;