import './App.css';
import { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Layout from './Components/Layout';
import Overlay from './Components/Overlay';
import Status from './Components/Status';
import TicTacToe from './Components/TicTacToe';

import { UserProvider } from "./Context/UserContext"
import { QueueProvider } from './Context/QueueContext';

function App() {
  return (
    <>
      <div className="App">
        <QueueProvider>
          <Routes>
            <Route path="/" element={<Layout />} />
            <Route path="/overlay" element={<Overlay />} />
            <Route path="/status" element={<Status />} />
            <Route path="/tictactoe" element={<TicTacToe />} />
          </Routes>
        </QueueProvider>
      </div>
    </>

  );
}

// here app catches the suspense from page in case translations are not yet loaded
export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <BrowserRouter>
        <UserProvider>
          <App />
        </UserProvider>
      </BrowserRouter>
    </Suspense>
  );
}
