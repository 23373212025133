import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Button from '@mui/material/Button';
import Link from '@mui/material/Link';

export default function Status() {
    const [status, setStatus] = useState(false)

    function checkSearchStatus() {
        fetch(process.env.REACT_APP_QUERY_SERVER + '/a', { mode: 'cors' })
            .then(res => res.json())
            .then(
                (result) => {
                    setStatus(true)
                },
                (error) => {
                    setStatus(false)
                }
            )
    }

    useEffect(() => {
        checkSearchStatus();
    }, []);

    const { t } = useTranslation();
    return (
        <Link target={status ? "_blank" : '_self'}
            href={status ? "https://requester.pumpcolombia.com/" : '#'}
            disabled={!status}>
            <Button variant="contained" disabled={!status}>{t(status ? 'Request your song' : 'Request Unavailable')}</Button>
        </Link>
    )
}