import React, { useState } from 'react';
import firebaseApp from '../firebase'
import { getAuth, onAuthStateChanged, signInAnonymously, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider } from "firebase/auth"
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const UserContext = React.createContext();
const auth = getAuth(firebaseApp);
const messaging = getMessaging();


const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const twitterProvider = new TwitterAuthProvider();

let userAccessToken = null;

export function UserProvider(props) {
    const [user, setUser] = useState({});
    const [fcmToken, setFcmToken] = useState(null);

    onAuthStateChanged(auth, (loadedUser) => {
        if (loadedUser) {
            setUser(loadedUser);
        } else {
            localStorage.removeItem('token')
        }
    });

    function getAccessToken() {
        return userAccessToken ? userAccessToken : (user.uid ? localStorage.getItem('token') : '');
    }

    function loginWith(provider = false) {
        //setProviderSource(provider);
        switch (provider) {
            case 'google':
                signInWithPopup(auth, googleProvider)
                    .then((result) => {
                        // This gives you a Google Access Token. You can use it to access the Google API.
                        //const credential = GoogleAuthProvider.credentialFromResult(result);
                        //userAccessToken = credential.accessToken;
                        //localStorage.setItem('token', userAccessToken);
                        // The signed-in user info.
                        //const user = result.user;

                    }).catch((error) => {
                        // Handle Errors here.
                        //const errorCode = error.code;
                        //const errorMessage = error.message;
                        // The email of the user's account used.
                        //const email = error.customData.email;
                        // The AuthCredential type that was used.
                        //const credential = GoogleAuthProvider.credentialFromError(error);
                        //setProviderSource(null);
                        signInAnonymously(auth);
                    });
                break;
            case 'facebook':
                signInWithPopup(auth, facebookProvider)
                    .then((result) => {
                        // The signed-in user info.
                        //const user = result.user;

                        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
                        const credential = FacebookAuthProvider.credentialFromResult(result);
                        const accessToken = credential.accessToken;

                        userAccessToken = "?access_token=" + accessToken;
                        localStorage.setItem('token', userAccessToken);
                    })
                    .catch((error) => {
                        // Handle Errors here.
                        //const errorCode = error.code;
                        //const errorMessage = error.message;
                        // The email of the user's account used.
                        //const email = error.customData.email;
                        // The AuthCredential type that was used.
                        //const credential = FacebookAuthProvider.credentialFromError(error);

                        signInAnonymously(auth);
                    });
                break;
            case 'twitter':
                signInWithPopup(auth, twitterProvider)
                    .then((result) => {
                        // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                        // You can use these server side with your app's credentials to access the Twitter API.
                        //const credential = TwitterAuthProvider.credentialFromResult(result);
                        //const token = credential.accessToken;
                        //const secret = credential.secret;

                        // The signed-in user info.
                        //userAccessToken = "?access_token=" + token;
                        //localStorage.setItem('token', token);
                        // ...
                    }).catch((error) => {
                        // Handle Errors here.
                        //const errorCode = error.code;
                        //const errorMessage = error.message;
                        // The email of the user's account used.
                        //const email = error.customData.email;
                        // The AuthCredential type that was used.
                        //const credential = TwitterAuthProvider.credentialFromError(error);
                        // ...
                        signInAnonymously(auth);
                    });
                break;
            case 'guest':
                signInAnonymously(auth);
                break;
            default:
                break;
        }
    }

    const requestForToken = (setOpen, setCurrentMessage, setSnackDuration) => {
        return getToken(messaging, { vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY })
            .then((currentToken) => {
                if (currentToken) {
                    localStorage.setItem("fcm_token", currentToken);
                    console.log('fcm token retrieved');
                    setFcmToken(currentToken);
                } else {
                    // Show permission request UI
                    console.log('No registration token available. Request permission to generate one.');
                    localStorage.removeItem("fcm_token")
                    setFcmToken(null);
                }
            })
            .catch((err) => {
                if (err.code === "messaging/permission-blocked") {
                    setCurrentMessage('The notification permission is blocked, please allow it.')
                    setOpen(true);
                    setSnackDuration(2000);
                    setFcmToken(null);
                } else {
                    console.log('An error occurred while retrieving token. ', err);
                }
            });
    };

    const onMessageListener = () =>
        new Promise((resolve) => {
            onMessage(messaging, (payload) => {
                resolve(payload);
            });
        });


    const value = React.useMemo(() => {
        return ({
            user,
            fcmToken,
            loginWith,
            getAccessToken,
            requestForToken,
            onMessageListener
        })
    }, [user])

    return <UserContext.Provider value={value} {...props} />
}

export function useProfile() {
    const context = React.useContext(UserContext);
    if (!context) {
        throw new Error('useProfile should be inside provider UserContext')
    }

    return context;
}