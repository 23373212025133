import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import { Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import { useTranslation } from "react-i18next";

import LanguageSelector from './LanguageSelector';
import Avatar from './Avatar';
import SearchBar from './SearchBar';
import RequestedSongs from './RequestedSongs';
import Cemetery from './Cemetery';
import ServiceWorkerWrapper from './ServiceWorkerWrapper';
import Notifier from './Notifier';

function ElevationScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 0,
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window: PropTypes.func,
};

export default function Layout(props) {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ServiceWorkerWrapper />
            <CssBaseline />
            <Notifier />
            <ElevationScroll {...props}>
                <AppBar>
                    <Toolbar disableGutters={true}>
                        <Grid container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center">
                            <Grid item xs={1} sm={1}>
                                <Box
                                    component="img"
                                    sx={{
                                        height: "100%",
                                        width: "100%",
                                        maxHeight: { xs: 25, md: 50 },
                                        maxWidth: { xs: 25, md: 50 },
                                    }}
                                    alt={t('Song Requester')}
                                    src="/images/xx-arrow.png"
                                />
                            </Grid>
                            <Grid item xs={8} sm={9}>
                                <SearchBar />
                            </Grid>
                            <Grid item xs={1} sm={1}>
                                <LanguageSelector />
                            </Grid>
                            <Grid item xs={2} sm={1}>
                                <Avatar />
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </ElevationScroll>
            <Toolbar disableGutters={true} />
            <Container disableGutters={true} sx={{ backgroundColor: '#000', color: '#fff' }} maxWidth='false'>
                <Grid container sx={{ backgroundColor: '#000', color: '#fff' }}>
                    <Grid item xs={12}>
                        <RequestedSongs />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                        <Cemetery />
                    </Grid>
                </Grid>

            </Container>
        </React.Fragment >
    );
}
