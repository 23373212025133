import { useEffect } from 'react';

import { Grid, Typography } from '@mui/material';

import { useTranslation } from "react-i18next";

import { useProfile } from "../Context/QueueContext";

export default function Cemetery(props) {
    const { cemetery } = useProfile();

    const { t } = useTranslation();

    useEffect(() => { }, [props]);

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">{t('Already Played')}</Typography>
            </Grid>
            {cemetery.length ? (
                <>{cemetery.map(function (song) {
                    return (
                        <Grid key={"cemetery-song-" + song.code} item xs={12}>
                            <Grid container>
                                <Grid item xs={2} sm={4}>
                                    <img
                                        key={"cemetery-song-image-" + song.code}
                                        src={song.image}
                                        width={"95%"}
                                        alt={song.name}
                                        loading="lazy"
                                    />
                                </Grid>
                                <Grid item xs={10} sm={8}>
                                    <p>{song.name}</p>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}</>
            ) : (
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div">{t('No songs.')}</Typography>
                </Grid>
            )}
        </Grid>
    );
}