import { useEffect, useState, forwardRef } from 'react';

import { Grid, Typography } from '@mui/material';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';

import { useTranslation } from "react-i18next";

import { useProfile } from "../Context/QueueContext";

export default function RequestedSongs(props) {
    const { upvoteSong, sendMessageToSocial } = useProfile();

    const [snackOpen, setSnackOpen] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState("info");
    const [snackMessage, setSnackMessage] = useState("");

    const { t } = useTranslation();

    const { requestedSongs } = useProfile();

    function callUpVoteSong(song) {
        upvoteSong(song).then((response) => {
            setSnackSeverity(response.severity);
            setSnackMessage(response.message);
            setSnackOpen(true);
            sendMessageToSocial(response, 'upvote');
        });
    }

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    useEffect(() => { }, [requestedSongs]);

    return (
        <Grid container>
            <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => { setSnackOpen(false) }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => { setSnackOpen(false) }} severity={snackSeverity} sx={{ width: '100%' }}>
                    {snackMessage}
                </Alert>
            </Snackbar>
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom component="div">{t('Requested Songs')}</Typography>
            </Grid>
            <Grid item xs={12}>
                <Grid container>
                    <Grid item xs={9} sm={10}>{t("Song")}</Grid>
                    <Grid item xs={2} sm={1}>{t("Votes")}</Grid>
                    <Grid item xs={1} sm={1}>
                        <Typography sx={{ display: { xs: 'none', md: 'block' } }}>{t("Action")}</Typography>
                        <Typography sx={{ display: { xs: 'block', md: 'none' } }}>{t("Act")}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            {requestedSongs.length ? (
                <>{requestedSongs.map(function (song, idx) {
                    return (
                        <Grid key={"requested-song-" + song.code} item xs={12} sx={{ backgroundColor: song.active ? '#aaa' : '#fff' }}>
                            <Grid container alignItems="center" sx={{ backgroundColor: song.active ? '#FFD700' : '#000', color: '#fff' }}>
                                <Grid item xs={2} sm={4}>
                                    <img
                                        key={"requested-song-image-" + song.code}
                                        src={song.image}
                                        width={"95%"}
                                        alt={song.name}
                                        loading="lazy"
                                    />
                                </Grid>
                                <Grid item xs={7} sm={6}>
                                    <p><b>{song.name}{song.active ? " (" + t('Playing') + ")" : ''}</b><br />{song.mix}</p>
                                </Grid>
                                <Grid item xs={2} sm={1}>
                                    <p>{song.votes < 999 ? song.votes : t('Forced')}</p>
                                    <Box alignItems="center"
                                        display="flex"
                                        flexDirection="column"
                                        gap={4}
                                        justifyContent="center"
                                        width={'100%'}>
                                        <AvatarGroup max={4}>
                                            {Object.keys(song.users).filter(voteUserId => song.users[voteUserId].auth === true).map(function (voteUserId) {
                                                return (<Avatar alt={song.users[voteUserId].name} key={"vote-" + voteUserId} src={song.users[voteUserId].avatar} sx={{ width: 24, height: 24 }} />)
                                            })}
                                        </AvatarGroup>
                                    </Box>
                                </Grid>
                                <Grid item xs={1} sm={1}>
                                    <Typography sx={{ display: { xs: 'none', md: 'block' } }}>
                                        <Button onClick={() => { callUpVoteSong({ id: song.code }) }} key={"requested-song-action-md-" + song.code} variant="contained" sx={{ color: '#fff' }}>
                                            {t('Upvote')}
                                        </Button>
                                    </Typography>
                                    <Typography key={"requested-song-action-xs" + song.code} sx={{ display: { xs: 'block', md: 'none' } }}>
                                        <IconButton sx={{ minHeight: 0, minWidth: 0, padding: 0 }} size="small" onClick={() => { callUpVoteSong({ id: song.code }) }} aria-label={t('Upvote')}>
                                            <ArrowUpwardIcon sx={{ color: '#fff' }} />
                                        </IconButton>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    )
                })}</>
            ) : (
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom component="div">{t('No songs. Request One!')}</Typography>
                </Grid>
            )}

        </Grid>
    );
}