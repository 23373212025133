import { useState, useCallback, useEffect, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

import debounce from 'lodash/debounce';

import { Grid } from '@mui/material';

import { useTranslation } from "react-i18next";

import { useProfile } from "../Context/QueueContext";


export default function SearchBar() {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const [snackOpen, setSnackOpen] = useState(false);
    const [snackSeverity, setSnackSeverity] = useState("info");
    const [snackMessage, setSnackMessage] = useState("");
    const [offlineMode, setOfflineMode] = useState(false);

    const { queueRequestSong, sendMessageToSocial, config } = useProfile();

    const { t } = useTranslation();

    function requestSong() {

        queueRequestSong(value).then((response) => {
            setSnackSeverity(response.severity);
            setSnackMessage(response.message);
            setSnackOpen(true);
            sendMessageToSocial(response, 'request');
        });
    };

    function requestRandomSong() {
        const alphabet = "abcdefghijklmnopqrstuvwxyz"

        const randomCharacter = alphabet[Math.floor(Math.random() * alphabet.length)]

        fetch(process.env.REACT_APP_QUERY_SERVER + '/' + randomCharacter, { mode: 'cors' })
            .then(res => res.json())
            .then(
                (result) => {
                    const randomSong = result[randomNumberInRange(0, result.length)]
                    queueRequestSong(randomSong).then((response) => {
                        setSnackSeverity(response.severity);
                        setSnackMessage(response.message);
                        setSnackOpen(true);
                        sendMessageToSocial(response, 'random');
                    });
                },
                (error) => {
                    setSnackSeverity('error');
                    setSnackMessage('Error, please try again');
                    setSnackOpen(true);
                }
            )
    }

    function randomNumberInRange(min, max) {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }


    const styles = (theme) => ({
        popper: {
            maxWidth: "fit-content"
        }
    });

    const PopperMy = function (props) {
        return <Popper {...props} style={styles.popper} placement="bottom-start" />;
    };

    const Alert = forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    const getOptionsDelayed = useCallback(
        debounce((text, value, callback) => {
            if (text === '') {
                callback([]);
            } else if (value && text === value.name + " (" + value.id + ")") {
                callback([value])
            } else {
                fetch(process.env.REACT_APP_QUERY_SERVER + '/' + text, { mode: 'cors' })
                    .then(res => res.json())
                    .then(
                        (result) => {
                            callback(result)
                        },
                        (error) => {
                            callback([])
                            setSnackSeverity('error');
                            setSnackMessage(t('Search server is not available. Try again later'));
                            setSnackOpen(true);
                        }
                    )
            }
        }, 200),
        [],
    );

    window.addEventListener('offline', () => {
        setOfflineMode(true);
    });

    window.addEventListener('online', () => {
        setOfflineMode(false);
    });

    useEffect(() => {
        getOptionsDelayed(inputValue, value, (filteredOptions) => {
            setOptions(filteredOptions);
        });
    }, [inputValue, value, getOptionsDelayed]);

    return (
        <Grid container
            justifyContent="space-between"
            alignItems="center">
            <Grid item xs={8} xm={11}>
                <Autocomplete
                    disabled={!config.openQueue || offlineMode}
                    id="grouped-demo"
                    options={options}
                    groupBy={(option) => option.version}
                    getOptionLabel={(option) => option.name + " (" + option.id + ")"}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    PopperComponent={PopperMy}
                    sx={{ width: "100%" }}
                    value={value}
                    onChange={(event, newValue) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setValue(newValue);
                    }}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderOption={(props, option) => {
                        return (
                            <li {...props}>
                                {option.name}
                            </li>
                        );
                    }}
                    renderInput={(params) => <TextField {...params} label={config.openQueue ? t("Request Song") : t("Queue Closed")} />}
                />
                <Snackbar open={snackOpen} autoHideDuration={2000} onClose={() => { setSnackOpen(false) }} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={() => { setSnackOpen(false) }} severity={snackSeverity} sx={{ width: '100%' }}>
                        {snackMessage}
                    </Alert>
                </Snackbar>
            </Grid>
            <Grid item xs={4} xm={1}>
                <Stack spacing={1} direction="row" sx={{ display: { xs: 'none', lg: 'block' } }}>
                    <Button disabled={!config.openQueue} onClick={requestSong} variant="contained" startIcon={<AddIcon />}>
                        {t("Request")}
                    </Button>
                    <Button disabled={!config.openQueue} onClick={requestRandomSong} variant="contained" startIcon={<QuestionMarkIcon />}>
                        {t("Random")}
                    </Button>
                </Stack>
                <Stack direction="row" sx={{ display: { xs: 'block', lg: 'none' } }}>
                    <IconButton disabled={!config.openQueue} onClick={requestSong} aria-label={t("Request")} size="medium">
                        <AddIcon fontSize="inherit" />
                    </IconButton>
                    <IconButton disabled={!config.openQueue} onClick={requestRandomSong} aria-label={t("Random")} size="medium">
                        <QuestionMarkIcon fontSize="small" />
                    </IconButton>
                </Stack>
            </Grid>
        </Grid>
    );
}
